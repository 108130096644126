//wkb banner slider
var $wkb_banner = $(document).find('.wkb-banner');
if( $wkb_banner.length > 0 ) {
  $.each($wkb_banner, function( index, banner ) {
    var $banner = $(banner);
    var $slides = $('.owl-carousel', $banner);
    if( $slides.length > 0 ) {
      var owl = $slides.owlCarousel({
        items: 1,
        dots: true,
        smartSpeed: 500,
        nav: true,
        loop: false,
        autoplay: $banner.data('autoslide'),
        autoplayTimeout: $banner.data('interval'),
      });
      //video controls
      var autoplay = $wkb_banner.data('autoplay');
      var $videos = $banner.find('.video');

      if( $videos.length > 0 ) {
        //autoplay first slide video
        var video = $('.video', $slides[0]).get(0);
        if( typeof video != 'undefined' && autoplay == 1 ) {
          playVideo($banner, video);
        }

        owl.on('changed.owl.carousel', function(property) {
          var current = property.item.index;
          var slide = $(property.target).find(".owl-item").eq(current);
          var video = slide.find('.video').get(0);
          //stop all videos
          stopAllVideos($banner, $videos);
          
        });

        $('.play-btn', $banner).on('click', function() {
          var video = $(this).next('.video').get(0);
          playVideo($banner, video);
        });
      }
    }
  });
}

function playVideo($banner, video) {
  video.play();
  $banner.addClass('playing');

  video.onended = function(e) {
    $banner.removeClass('playing');
  };
}
function stopAllVideos($banner, $videos) {
  $videos.each(function() {
    $(this).get(0).pause();
    $(this).get(0).currentTime = 0;
  });
  $banner.removeClass('playing');
}